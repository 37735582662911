import React, { FC } from 'react';
import { UserOutlined } from '@ant-design/icons';

import { Avatar, Flex, Text } from 'components/ui';
import { User as TUser } from 'types/api';

import styles from './styles.module.css';

type UserProps = {
  user: TUser;
};

const User: FC<UserProps> = ({ user }) => (
  <Flex gap="small" align="center">
    <Avatar
      className={styles.avatar}
      icon={<UserOutlined />}
      src={user.image}
      size={24}
    />
    <Text title={user.name} ellipsis>{user.name}</Text>
  </Flex>
);

export default User;
