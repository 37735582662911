import { axios } from 'data';
import { Uuid } from 'types/general';

import {
  Complaint,
  ComplaintSortField,
  ComplaintStatus,
  FetchComplaintsParams,
  FetchComplaintsResponse,
  SortOrder,
} from 'types/api';

const fetchComplaints = async (params?: FetchComplaintsParams) => {
  const response = await axios.get<FetchComplaintsResponse>('/admin/complaint/user-profile', {
    params: {
      sort: [
        `${ComplaintSortField.STATUS},${SortOrder.ASC}`,
        `${ComplaintSortField.DATE},${SortOrder.ASC}`,
      ],
      ...params,
    },
  });

  return response.data;
};

const fetchComplaint = async (id: Uuid) => {
  const response = await axios.get<Complaint>(`/admin/complaint/user-profile/${id}`);

  return response.data;
};

const updateComplaintStatus = async (id: Uuid, status: ComplaintStatus) => {
  const response = await axios.put<never>(`/admin/complaint/user-profile/${id}/status/${status}`);

  return response.status === 200;
};

const acceptComplaint = (id: Uuid) => updateComplaintStatus(id, ComplaintStatus.ACCEPTED);

const rejectComplaint = (id: Uuid) => updateComplaintStatus(id, ComplaintStatus.REJECTED);

const complaintService = {
  fetchComplaints,
  fetchComplaint,
  acceptComplaint,
  rejectComplaint,
};

export default complaintService;
