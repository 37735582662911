import { useEffect } from 'react';
import { AxiosError } from 'axios';

import {
  DefaultError,
  useMutation as useBaseMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';

import useTheme from './theme';

const KEY = 'mutation';

type UseMutation = <
  TData = unknown,
  TError extends DefaultError = AxiosError,
  TVariables = void,
  TContext = unknown,
>(options: UseMutationOptions<TData, TError, TVariables, TContext>) => UseMutationResult<TData, TError, TVariables, TContext>;

const useMutation: UseMutation = (options) => {
  const theme = useTheme();
  const mutation = useBaseMutation(options);

  useEffect(() => {
    if (mutation.isPending) {
      theme.message?.loading({
        key: KEY,
        content: 'Loading...',
        duration: 0,
      });
    } else {
      theme.message?.destroy(KEY);
    }

    return () => {
      theme.message?.destroy(KEY);
    };
  }, [theme.message, mutation.isPending]);

  useEffect(() => {
    if (mutation.isError) {
      theme.notification?.error({
        message: 'Error',
        description: mutation.error.message,
        duration: 10,
        onClose: mutation.reset,
      });
    }
  }, [theme.notification, mutation.isError, mutation.error, mutation.reset]);

  return mutation;
};

export default useMutation;
