import React, { FC } from 'react';
import { List } from 'antd';
import { ListItemProps } from 'antd/lib/list';
import cx from 'classnames';

import styles from './styles.module.css';

const Item: FC<ListItemProps> = ({ className, ...props }) => (
  <List.Item
    className={cx(styles.item, className)}
    {...props}
  />
);

export default Item;
