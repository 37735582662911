import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';

import { App, Auth, Preloader, Query, Theme } from 'context';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <Auth.Provider>
    <Theme.Provider>
      <Preloader.Provider>
        <Query.Provider>
          <App.Provider />
        </Query.Provider>
      </Preloader.Provider>
    </Theme.Provider>
  </Auth.Provider>,
);
