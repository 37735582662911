import { FC } from 'react';

import { dayjs } from 'data';

type ComplaintDateProps = {
  date: string;
};

const ComplaintDate: FC<ComplaintDateProps> = ({ date }) => dayjs(date).format('LLL');

export default ComplaintDate;
