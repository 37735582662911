import React, { createContext, FC, ReactNode, useCallback, useMemo, useRef, useState } from 'react';

import { Preloader as PreloaderComponent } from 'components/ui';

type ContextValue = {
  show: () => void;
  hide: () => void;
  toggle: (active: boolean) => void;
};

type ProviderProps = {
  children: ReactNode;
};

const PreloaderContext = createContext<ContextValue>({
  show: () => undefined,
  hide: () => undefined,
  toggle: () => undefined,
});

const PreloaderProvider: FC<ProviderProps> = ({ children }) => {
  const [active, setActive] = useState(false);

  const queue = useRef(0);

  const toggle = useCallback((active: boolean) => {
    queue.current += active ? 1 : -1;
    queue.current = Math.max(queue.current, 0);

    if (!active && queue.current) {
      return;
    }

    setActive(active);
  }, []);

  const show = useCallback(() => toggle(true), [toggle]);

  const hide = useCallback(() => toggle(false), [toggle]);

  const value = useMemo(() => ({
    show,
    hide,
    toggle,
  }), [show, hide, toggle]);

  return (
    <PreloaderContext.Provider value={value}>
      {children}
      <PreloaderComponent active={active} />
    </PreloaderContext.Provider>
  );
};

const Preloader = {
  Context: PreloaderContext,
  Provider: PreloaderProvider,
};

export default Preloader;
