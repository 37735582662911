import React, { FC } from 'react';
import { PresetStatusColorType } from 'antd/es/_util/colors';

import { Tag } from 'components/ui';
import { ComplaintStatus as EComplaintStatus } from 'types/api';

type ComplaintStatusProps = {
  status: EComplaintStatus;
};

const ComplaintStatus: FC<ComplaintStatusProps> = ({ status }) => {
  let color: PresetStatusColorType = 'warning';
  let label = 'Not reviewed';

  switch (status) {
    case EComplaintStatus.ACCEPTED:
      color = 'error';
      label = 'Blocked';
      break;
    case EComplaintStatus.REJECTED:
      color = 'success';
      label = 'Rejected';
      break;
  }

  return (
    <Tag color={color}>{label}</Tag>
  );
};

export default ComplaintStatus;
