import React, { useEffect } from 'react';
import { CheckOutlined, DeleteOutlined, StopOutlined, WarningOutlined } from '@ant-design/icons';
import { useQueryClient } from '@tanstack/react-query';

import { config } from 'data';
import { useList, useMutation, useQuery } from 'hooks';
import { photoService, userService } from 'services';
import { DashboardLayout } from 'layouts';
import { User } from 'components/layout';
import { Card, Flex, Image, List, Text } from 'components/ui';
import { Photo } from 'types/api';

import styles from './styles.module.css';

import Action from './Action';

const UploadedPhotosPage = () => {
  const list = useList<Photo>(config.PHOTOS_QUERY_KEY);
  const queryClient = useQueryClient();

  const page = list.pagination ? list.pagination.current : 1;

  const photosQuery = useQuery({
    queryKey: [config.PHOTOS_QUERY_KEY, page],
    queryFn: () => photoService.fetchPhotos({ page }),
  });

  const invalidatePhotoQueries = async () => {
    await queryClient.invalidateQueries({ queryKey: [config.PHOTOS_QUERY_KEY] });
  };

  const approvePhotoMutation = useMutation({
    mutationFn: photoService.approvePhoto,
    onSuccess: invalidatePhotoQueries,
  });

  const rejectPhotoMutation = useMutation({
    mutationFn: photoService.rejectPhoto,
    onSuccess: invalidatePhotoQueries,
  });

  const blockUserMutation = useMutation({
    mutationFn: userService.blockUser,
    onSuccess: invalidatePhotoQueries,
  });

  useEffect(() => {
    if (photosQuery.data) {
      const { content, pageable, totalElements } = photosQuery.data;

      list.setData(content);

      list.setPagination({
        current: pageable.pageNumber,
        pageSize: pageable.pageSize,
        total: totalElements,
      });
    }
  }, [list.setData, list.setPagination, photosQuery.data]); /* eslint-disable-line react-hooks/exhaustive-deps */

  const renderActions = (photo: Photo) => [
    <Action
      key="approve-photo"
      icon={<CheckOutlined />}
      title="Are you sure you want to approve the photo?"
      tooltip="Approve photo"
      loading={approvePhotoMutation.isPending}
      onConfirm={() => approvePhotoMutation.mutate(photo.id)}
    />,
    <Action
      key="reject-photo"
      icon={<DeleteOutlined />}
      title="Are you sure you want to delete the photo?"
      description={photo.isMainPhoto && (
        <Text type="warning">
          <WarningOutlined /> You're about to delete the main photo.
        </Text>
      )}
      tooltip="Delete photo"
      loading={rejectPhotoMutation.isPending}
      onConfirm={() => rejectPhotoMutation.mutate(photo.id)}
    />,
    <Action
      key="block-user"
      icon={<StopOutlined />}
      title="Are you sure you want to block the user?"
      description={(
        <Text type="warning">
          <WarningOutlined /> It'll prevent them from further using the application.
        </Text>
      )}
      tooltip="Block user"
      loading={blockUserMutation.isPending}
      onConfirm={() => blockUserMutation.mutate(photo.user.id)}
    />,
  ];

  return (
    <DashboardLayout
      title="Uploaded photos"
      heading="Uploaded photos"
    >
      <List<Photo>
        grid={{ xs: 1, sm: 2, md: 3, lg: 3, xl: 4, xxl: 5 }}
        dataSource={list.data}
        pagination={list.pagination}
        loading={photosQuery.isFetching}
        rowKey={(photo) => photo.id}
        renderItem={(photo) => (
          <List.Item>
            <Card
              cover={(
                <Image
                  wrapperClassName={styles.card__image}
                  src={photo.url}
                  preview={{
                    extraToolbar: (
                      <Flex
                        className={styles.preview__toolbar}
                        align="center"
                        justify="center"
                        wrap="wrap"
                      >
                        {renderActions(photo)}
                      </Flex>
                    ),
                  }}
                />
              )}
              actions={renderActions(photo)}
            >
              <User user={photo.user} />
            </Card>
          </List.Item>
        )}
      />
    </DashboardLayout>
  );
};

export default UploadedPhotosPage;
