import React, { FC, ReactNode } from 'react';
import { Layout } from 'antd';
import { Container } from 'components/ui';

import styles from './styles.module.css';

type FooterProps = {
  children: ReactNode;
};

const Footer: FC<FooterProps> = ({ children }) => (
  <Layout.Footer className={styles.footer}>
    <Container className={styles.footer__container}>
      {children}
    </Container>
  </Layout.Footer>
);

export default Footer;
