import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { config } from 'data';
import { url } from 'helpers';
import { usePreloader, useQuery } from 'hooks';
import { complaintService } from 'services';
import { DashboardLayout } from 'layouts';
import { NotFoundPage } from 'pages';
import { Spin } from 'components/ui';
import { Complaint } from 'types/api';
import { Uuid } from 'types/general';

import Actions from './Actions';
import Info from './Info';

type ReportedUserPageParams = {
  id: Uuid;
};

const ReportedUserPage = () => {
  const params = useParams<ReportedUserPageParams>();
  const preloader = usePreloader();

  const [complaint, setComplaint] = useState<Complaint>();

  const complaintQuery = useQuery({
    queryKey: [config.COMPLAINT_QUERY_KEY, params.id],
    queryFn: () => complaintService.fetchComplaint(params.id as string),
  });

  useEffect(() => {
    preloader.toggle(complaintQuery.isFetching);
  }, [preloader, complaintQuery.isFetching]);

  useEffect(() => {
    if (complaintQuery.data) {
      setComplaint(complaintQuery.data);
    }
  }, [complaintQuery.data]);

  if (complaintQuery.isError) {
    return <NotFoundPage returnUrl={url.toReportedUsers()} />;
  }

  const title = complaint
    ? `Reported user: ${complaint.user.name}`
    : 'Reported user';

  return (
    <DashboardLayout
      title={title}
      heading={title}
      footer={complaint && <Actions complaint={complaint} />}
    >
      {complaint ? <Info complaint={complaint} /> : <Spin />}
    </DashboardLayout>
  );
};

export default ReportedUserPage;
