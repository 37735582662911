import { useCallback, useMemo, useState } from 'react';
import { useLocalStorage } from '@uidotdev/usehooks';

import { TableChangeHandler, TableData, TablePagination } from 'types/components';

type TableState = {
  pagination: TablePagination;
};

const useTable = <DataType>(key: string) => {
  const [data, setData] = useState<TableData<DataType>>([]);

  const [state, saveState] = useLocalStorage<TableState>(`table:${key}`, {
    pagination: false,
  });

  const setPagination = useCallback((pagination: TablePagination) => {
    saveState((prevState) => ({ ...prevState, pagination }));
  }, [saveState]);

  const handleChange = useCallback<NonNullable<TableChangeHandler<DataType>>>((pagination) => {
    saveState({ pagination });
  }, [saveState]);

  return useMemo(() => ({
    ...state,
    data,
    setData,
    setPagination,
    onChange: handleChange,
  }), [data, state, setPagination, handleChange]);
};

export default useTable;
