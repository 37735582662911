import React, { ReactNode } from 'react';
import { FlagOutlined, PictureOutlined } from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';

import { url } from 'helpers';
import { Logo } from 'components/icons';

import styles from './styles.module.css';

type Item = {
  label: string;
  url: string;
  icon: ReactNode;
};

const items: Item[] = [
  {
    label: 'Uploaded photos',
    url: url.toUploadedPhotos(),
    icon: <PictureOutlined />,
  }, {
    label: 'Reported users',
    url: url.toReportedUsers(),
    icon: <FlagOutlined />,
  },
];

const Sidebar = () => {
  const location = useLocation();

  return (
    <Layout.Sider
      className={styles.sidebar}
      breakpoint="lg"
      collapsedWidth={0}
    >
      <Link className={styles.sidebar__logo} to={url.toDashboard()}>
        <Logo className={styles.sidebar__logo__icon} />
      </Link>
      <Menu
        className={styles.sidebar__menu}
        items={items.map(({ label, url, icon }) => ({
          className: styles.sidebar__menu__item,
          key: url,
          label: <Link to={url}>{label}</Link>,
          icon,
        }))}
        selectedKeys={items.map(({ url }) => url).filter((url) => url === location.pathname)}
        mode="inline"
        selectable={false}
      />
    </Layout.Sider>
  );
};

export default Sidebar;
