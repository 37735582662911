import React, { FC, ReactNode } from 'react';

import { BaseLayout } from 'layouts';
import { Title } from 'components/ui';
import { BaseLayoutProps } from 'types/layouts';

import styles from './styles.module.css';

import Content from './Content';
import Footer from './Footer';
import Header from './Header';
import Layout from './Layout';
import Sidebar from './Sidebar';

type DashboardLayoutProps = BaseLayoutProps & {
  heading?: string;
  footer?: ReactNode;
};

const DashboardLayout: FC<DashboardLayoutProps> = ({ title, heading, footer, children, ...props }) => (
  <BaseLayout title={title} {...props}>
    <Layout className={styles.root} hasSider>
      <Sidebar />
      <Layout>
        <Header />
        <Content>
          {heading && <Title className={styles.title} level={2}>{heading}</Title>}
          {children}
        </Content>
        {footer && <Footer>{footer}</Footer>}
      </Layout>
    </Layout>
  </BaseLayout>
);

export default DashboardLayout;
