import React, { FC, ReactNode } from 'react';
import { QueryClientProvider } from '@tanstack/react-query';

import { query } from 'data';

type ProviderProps = {
  children: ReactNode;
};

const QueryProvider: FC<ProviderProps> = ({ children }) => (
  <QueryClientProvider client={query}>
    {children}
  </QueryClientProvider>
);

const Query = {
  Provider: QueryProvider,
};

export default Query;
