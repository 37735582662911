import React from 'react';
import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { Layout, Menu } from 'antd';

import { useAuth } from 'hooks';
import { Container } from 'components/ui';

import styles from './styles.module.css';

const Header = () => {
  const auth = useAuth();

  return (
    <Layout.Header className={styles.header}>
      <Container className={styles.header__container}>
        <Menu
          className={styles.header__menu}
          items={[{
            className: styles.header__menu__item,
            key: 'user',
            label: auth.user?.email ?? auth.user?.username,
            icon: <UserOutlined />,
            children: [{
              key: 'logout',
              label: 'Logout',
              icon: <LogoutOutlined />,
              onClick: auth.logout,
            }],
          }]}
          mode="horizontal"
          selectable={false}
          disabledOverflow
        />
      </Container>
    </Layout.Header>
  );
};

export default Header;
