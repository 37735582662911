import React from 'react';

import { DashboardLayout } from 'layouts';
import { Flex, Paragraph, Title } from 'components/ui';

import styles from './styles.module.css';

const DashboardPage = () => (
  <DashboardLayout title="Dashboard">
    <Flex
      className={styles.root}
      align="center"
      justify="center"
      vertical
    >
      <Title>Welcome to Kosmo admin panel!</Title>
      <Paragraph>⬅️ Use the sidebar to navigate through the admin panel.</Paragraph>
    </Flex>
  </DashboardLayout>
);

export default DashboardPage;
