import { useCallback, useMemo } from 'react';
import { useKeycloak } from '@react-keycloak/web';

type User = {
  id: string;
  firstName?: string;
  lastName?: string;
  name?: string;
  email?: string;
  username: string;
};

const useAuth = () => {
  const { keycloak, initialized } = useKeycloak();

  const login = useCallback(() => keycloak.login(), [keycloak]);

  const logout = useCallback(() => keycloak.logout(), [keycloak]);

  const authenticated = keycloak.authenticated ?? false;

  const user = useMemo<User | undefined>(() => keycloak.tokenParsed && {
    id: keycloak.tokenParsed.sub as string,
    firstName: keycloak.tokenParsed.given_name || undefined,
    lastName: keycloak.tokenParsed.family_name || undefined,
    name: keycloak.tokenParsed.name,
    email: keycloak.tokenParsed.email,
    username: keycloak.tokenParsed.preferred_username,
  }, [keycloak.tokenParsed]);

  return useMemo(() => ({
    initialized,
    authenticated,
    user,
    login,
    logout,
  }), [initialized, authenticated, user, login, logout]);
};

export default useAuth;
