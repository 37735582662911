import React, { FC } from 'react';
import { Table as BaseTable, TableProps } from 'antd';
import { AnyObject } from 'antd/es/_util/type';

import Actions from './Actions';

type TableComponent = <DataType extends AnyObject>(props: TableProps<DataType>) => ReturnType<FC<TableProps<DataType>>>;

const Table: TableComponent = ({ scroll = { x: 0 }, ...props }) => (
  <BaseTable
    scroll={scroll}
    {...props}
  />
);

export default Object.assign(Table, { Actions });
