import React, { FC, ReactNode } from 'react';
import { AuthClientError, AuthClientEvent } from '@react-keycloak/core';
import { ReactKeycloakProvider } from '@react-keycloak/web';

import { keycloak } from 'data';
import { url } from 'helpers';

type ProviderProps = {
  children: ReactNode;
};

const AuthProvider: FC<ProviderProps> = ({ children }) => {
  const handleEvent = (_: AuthClientEvent, error?: AuthClientError) => {
    if (error) {
      const errorCode = error.error;
      const errorMessage = error.error_description.replace(/\+/g, ' ');

      window.location.href = url.toInternalError(errorCode, errorMessage);
    }
  };

  return (
    <ReactKeycloakProvider authClient={keycloak} onEvent={handleEvent}>
      {children}
    </ReactKeycloakProvider>
  );
};

const Auth = {
  Provider: AuthProvider,
};

export default Auth;
