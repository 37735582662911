import { theme, ThemeConfig } from 'antd';

enum Color {
  NONE = 'transparent',
  DARK = '#06000e',
  DARK_ACCENT = '#110922',
  GRAY_LIGHTEST = 'rgba(243, 234, 255, 0.16)',
  ERROR = '#d92b2b',
  WARNING = '#fda965',
  SUCCESS = '#24c775',
  PRIMARY = '#9865fd',
}

const themeConfig: ThemeConfig = {
  algorithm: theme.darkAlgorithm,
  token: {
    fontFamily: '"Poppins", sans-serif',
    colorError: Color.ERROR,
    colorWarning: Color.WARNING,
    colorSuccess: Color.SUCCESS,
    colorPrimary: Color.PRIMARY,
  },
  components: {
    Card: {
      colorBgContainer: Color.DARK_ACCENT,
    },
    Layout: {
      bodyBg: Color.NONE,
      footerBg: Color.DARK,
      footerPadding: 0,
      headerBg: Color.DARK,
      headerPadding: 0,
      siderBg: Color.DARK_ACCENT,
    },
    Menu: {
      colorBgElevated: Color.DARK_ACCENT,
      boxShadowSecondary: `0 0 0 1px ${Color.GRAY_LIGHTEST}`,
    },
    Message: {
      colorBgElevated: Color.DARK_ACCENT,
      boxShadow: `0 0 0 1px ${Color.GRAY_LIGHTEST}`,
    },
    Notification: {
      colorBgElevated: Color.DARK_ACCENT,
      boxShadow: `0 0 0 1px ${Color.GRAY_LIGHTEST}`,
    },
    Table: {
      colorBgContainer: Color.DARK_ACCENT,
      headerBg: Color.DARK_ACCENT,
      borderColor: Color.GRAY_LIGHTEST,
    },
  },
};

export default themeConfig;
