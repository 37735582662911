export enum HttpStatus {
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  INTERNAL_SERVER_ERROR = 500,
}

export type Nullable<T> = T | null;

export type Override<T, O> = Omit<T, keyof O> & O;

export type Uuid = string;
