import React, { FC, ReactNode } from 'react';
import { Layout } from 'antd';

import { Container } from 'components/ui';

import styles from './styles.module.css';

type ContentProps = {
  children: ReactNode;
};

const Content: FC<ContentProps> = ({ children }) => (
  <Layout.Content>
    <Container className={styles.content__container}>{children}</Container>
  </Layout.Content>
);

export default Content;
