import React, { FC } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ErrorPage } from 'pages';
import { HttpStatus } from 'types/general';
import { ErrorPageProps } from 'types/pages';
import { RouteError } from 'types/routes';

const error = new Error('Service temporarily unavailable.') as RouteError;

error.status = HttpStatus.INTERNAL_SERVER_ERROR;

const InternalErrorPage: FC<ErrorPageProps> = ({ customError = error, ...props }) => {
  const [searchParams] = useSearchParams();

  const errorCode = searchParams.get('code');
  const errorMessage = searchParams.get('message');

  if (errorCode) {
    customError.message = `${errorCode}: ${errorMessage}`;
  }

  return (
    <ErrorPage customError={customError} {...props} />
  );
};

export default InternalErrorPage;
