import React, { FC } from 'react';

import { Text } from 'components/ui';
import { Nullable } from 'types/general';

type ComplaintReasonProps = {
  reason: Nullable<string>;
};

const ComplaintReason: FC<ComplaintReasonProps> = ({ reason }) => reason ?? <Text disabled>(no reason)</Text>;

export default ComplaintReason;
