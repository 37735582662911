import React, { FC, ReactNode } from 'react';
import { LoadingOutlined } from '@ant-design/icons';

import { Popconfirm, Tooltip } from 'components/ui';

type ActionProps = {
  icon: ReactNode;
  title: string;
  description?: ReactNode;
  tooltip: string;
  loading?: boolean;
  onConfirm: () => void;
};

const Action: FC<ActionProps> = ({
  icon,
  title,
  description,
  tooltip,
  loading,
  onConfirm,
}) => (
  <Popconfirm
    title={title}
    description={description}
    disabled={loading}
    onConfirm={onConfirm}
  >
    <Tooltip title={tooltip}>
      {loading ? <LoadingOutlined /> : icon}
    </Tooltip>
  </Popconfirm>
);

export default Action;
