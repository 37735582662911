import axios, { AxiosError, AxiosResponse, isAxiosError } from 'axios';

import { HttpStatus } from 'types/general';

import config from './config';
import keycloak from './keycloak';

const getErrorMessage = (error: AxiosError): string => {
  const response = error.response ?? {} as AxiosResponse;
  const { data, status } = response;

  if (data && data.message) {
    return data.message;
  }

  switch (status) {
    case HttpStatus.BAD_REQUEST:
      return 'Incorrect request.';
    case HttpStatus.UNAUTHORIZED:
      return 'Authentication required.';
    case HttpStatus.FORBIDDEN:
      return 'Access denied.';
    case HttpStatus.NOT_FOUND:
      return 'Resource not found.';
    case HttpStatus.INTERNAL_SERVER_ERROR:
      return 'Service temporarily unavailable.';
  }

  return error.message;
};

const axiosInstance = axios.create({
  baseURL: config.API_URL,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
  timeout: 5000,
});

axiosInstance.interceptors.request.use((config) => {
  config.headers.setAuthorization(`Bearer ${keycloak.token}`);

  return config;
});

axiosInstance.interceptors.response.use((response) => {
  return response;
}, (error) => {
  if (isAxiosError(error)) {
    if (error.response?.status === HttpStatus.UNAUTHORIZED) {
      keycloak.logout();
    }

    error.message = getErrorMessage(error);
  }

  return Promise.reject(error);
});

export default axiosInstance;
