import { axios } from 'data';
import { FetchPhotosParams, FetchPhotosResponse, PhotoSortField, PhotoStatus, SortOrder } from 'types/api';
import { Uuid } from 'types/general';

const fetchPhotos = async (params?: FetchPhotosParams) => {
  const response = await axios.get<FetchPhotosResponse>('/admin/photo', {
    params: {
      sort: [`${PhotoSortField.DATE},${SortOrder.ASC}`],
      status: [PhotoStatus.NEW],
      ...params,
    },
  });

  return response.data;
};

const updatePhotoStatus = async (id: Uuid, status: PhotoStatus) => {
  const response = await axios.put<never>(`/admin/photo/${id}/status/${status}`);

  return response.status === 200;
};

const approvePhoto = (id: Uuid) => updatePhotoStatus(id, PhotoStatus.APPROVED);

const rejectPhoto = (id: Uuid) => updatePhotoStatus(id, PhotoStatus.REJECTED);

const photoService = {
  fetchPhotos,
  approvePhoto,
  rejectPhoto,
};

export default photoService;
