import { useEffect } from 'react';
import { AxiosError } from 'axios';

import {
  DefaultError,
  QueryKey,
  useQuery as useBaseQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import useTheme from './theme';

const KEY = 'query';

type UseQuery = <
  TQueryFnData = unknown,
  TError extends DefaultError = AxiosError,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(options: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>) => UseQueryResult<TData, TError>;

const useQuery: UseQuery = (options) => {
  const theme = useTheme();

  const query = useBaseQuery({
    ...options,
    retry: options.retry ?? 1,
  });

  useEffect(() => {
    if (query.isFetching) {
      theme.message?.loading({
        key: KEY,
        content: 'Fetching...',
        duration: 0,
      });
    } else {
      theme.message?.destroy(KEY);
    }

    return () => {
      theme.message?.destroy(KEY);
    };
  }, [theme.message, query.isFetching]);

  useEffect(() => {
    if (query.isError) {
      theme.notification?.error({
        message: 'Fetch error',
        description: query.error.message,
        duration: 10,
      });
    }
  }, [theme.notification, query.isError, query.error]);

  return query;
};

export default useQuery;
