import { Nullable, Uuid } from './general';

export enum ComplaintType {
  SPAM = 'SPAM',
  FAKE_ACCOUNT = 'FAKE_ACCOUNT',
  VIOLENCE = 'VIOLENCE',
  PORNOGRAPHY = 'PORNOGRAPHY',
  CHILD_ABUSE = 'CHILD_ABUSE',
  OTHER = 'OTHER',
}

export enum ComplaintStatus {
  NEW = 'NEW',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
}

export enum ComplaintSortField {
  DATE = 'audit.createdDate',
  STATUS = 'status',
}

export enum PhotoStatus {
  NEW = 'NEW',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export enum PhotoSortField {
  DATE = 'createdDate',
}

export enum SortOrder {
  ASC = 'asc',
  DESC = 'desc',
}

export type Complaint = {
  id: Uuid;
  user: User;
  type: ComplaintType;
  status: ComplaintStatus;
  message: Nullable<string>;
  screenShotUrl: Nullable<string>;
  totalComplaints: number;
  createdBy: User;
  createdDate: string;
};

export type Photo = {
  id: Uuid;
  user: User;
  status: PhotoStatus;
  url: string;
  isMainPhoto: boolean;
  createdDate: string;
};

export type User = {
  id: string;
  name: string;
  image?: string;
};

type PageableParams = {
  page?: number;
  size?: number;
  sort?: string[];
};

type PageableResponse<ContentType> = {
  pageable: {
    pageNumber: number;
    pageSize: number;
  },
  totalPages: number;
  totalElements: number;
  content: ContentType;
};

export type FetchComplaintsParams = PageableParams & {
  status?: ComplaintStatus[];
};

export type FetchComplaintsResponse = PageableResponse<Complaint[]>;

export type FetchPhotosParams = PageableParams & {
  status?: PhotoStatus[];
};

export type FetchPhotosResponse = PageableResponse<Photo[]>;
