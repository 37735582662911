import React, { FC } from 'react';
import { List as BaseList, ListProps } from 'antd';
import { AnyObject } from 'antd/es/_util/type';

import Item from './Item';

type ListComponent = <DataType extends AnyObject>(props: ListProps<DataType>) => ReturnType<FC<ListProps<DataType>>>;

const List: ListComponent = ({ grid, pagination, ...props }) => (
  <BaseList
    grid={grid && {
      gutter: 48,
      ...grid,
    }}
    pagination={pagination && {
      showSizeChanger: false,
      ...pagination,
    }}
    {...props}
  />
);

export default Object.assign(List, { Item });
