import { useCallback, useMemo, useState } from 'react';
import { useLocalStorage } from '@uidotdev/usehooks';

import { ListPagination, PaginationChangeHandler } from 'types/components';

type ListState = {
  pagination: ListPagination;
};

const useTable = <DataType>(key: string) => {
  const [data, setData] = useState<DataType[]>([]);

  const [state, saveState] = useLocalStorage<ListState>(`list:${key}`, {
    pagination: false,
  });

  const setPagination = useCallback((pagination: ListPagination) => {
    saveState((prevState) => ({ ...prevState, pagination }));
  }, [saveState]);

  const handlePaginationChange = useCallback<NonNullable<PaginationChangeHandler>>((page, size) => {
    saveState((prevState) => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        current: page,
        pageSize: size,
      },
    }));
  }, [saveState]);

  return useMemo(() => ({
    data,
    pagination: {
      ...state.pagination,
      onChange: handlePaginationChange,
    },
    setData,
    setPagination,
  }), [data, state, setPagination, handlePaginationChange]);
};

export default useTable;
