const config = {
  API_URL: process.env.REACT_APP_API_URL as string,

  KEYCLOAK_CLIENT_ID: process.env.REACT_APP_KEYCLOAK_CLIENT_ID as string,
  KEYCLOAK_REALM: process.env.REACT_APP_KEYCLOAK_REALM as string,
  KEYCLOAK_URL: process.env.REACT_APP_KEYCLOAK_URL as string,

  COMPLAINTS_QUERY_KEY: 'complaints',
  COMPLAINT_QUERY_KEY: 'complaint',
  PHOTOS_QUERY_KEY: 'photos',
};

export default config;
