import React, { FC } from 'react';
import { Modal as BaseModal, ModalProps } from 'antd';

const Modal: FC<ModalProps> = ({ centered = true, ...props }) => (
  <BaseModal
    centered={centered}
    {...props}
  />
);

export default Modal;
