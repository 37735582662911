import React, { FC } from 'react';
import { Link, useRouteError } from 'react-router-dom';

import { url } from 'helpers';
import { PageLayout } from 'layouts';
import { Button, Paragraph, Title } from 'components/ui';
import { HttpStatus } from 'types/general';
import { ErrorPageProps } from 'types/pages';
import { RouteError } from 'types/routes';

import styles from './styles.module.css';

const ErrorPage: FC<ErrorPageProps> = ({ customError, returnUrl }) => {
  const routeError = useRouteError() as RouteError;

  const error = customError ?? routeError;

  let title = 'Error';
  let heading = 'Sorry, something went wrong';
  let message = error.statusText || error.message;

  if (error.status === HttpStatus.NOT_FOUND) {
    title = 'Page not found';
    heading = 'Sorry, this page isn\'t available';
    message = 'The link you followed may be broken, or the page may have been removed.';
  }

  return (
    <PageLayout title={title}>
      <div className={styles.root}>
        <Title>{heading}</Title>
        <Paragraph>{message}</Paragraph>
        <Link to={returnUrl ?? url.toDashboard()}>
          <Button block>Back</Button>
        </Link>
      </div>
    </PageLayout>
  );
};

export default ErrorPage;
