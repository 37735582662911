import React, { FC } from 'react';

import { ComplaintDate, ComplaintReason, ComplaintStatus, ComplaintType, User } from 'components/layout';
import { Descriptions, Image, Text } from 'components/ui';
import { Complaint } from 'types/api';

type InfoProps = {
  complaint: Complaint;
};

const Info: FC<InfoProps> = ({ complaint }) => (
  <Descriptions column={{ xs: 1, sm: 1, md: 2, lg: 2, xl: 2, xxl: 2 }}>
    <Descriptions.Item label="Date">
      <ComplaintDate date={complaint.createdDate} />
    </Descriptions.Item>
    <Descriptions.Item label="Status">
      <ComplaintStatus status={complaint.status} />
    </Descriptions.Item>
    <Descriptions.Item label="Type">
      <ComplaintType type={complaint.type} />
    </Descriptions.Item>
    <Descriptions.Item label="Complaints quantity">{complaint.totalComplaints}</Descriptions.Item>
    <Descriptions.Item label="Reporter" span={2}>
      <User user={complaint.createdBy} />
    </Descriptions.Item>
    <Descriptions.Item label="Reason" span={2}>
      <ComplaintReason reason={complaint.message} />
    </Descriptions.Item>
    <Descriptions.Item label="Screenshot" span={2}>&nbsp;</Descriptions.Item>
    <Descriptions.Item span={2}>
      {complaint.screenShotUrl
        ? <Image src={complaint.screenShotUrl} width={240} />
        : <Text disabled>(no screenshot)</Text>}
    </Descriptions.Item>
  </Descriptions>
);

export default Info;
