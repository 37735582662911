import { Uuid } from 'types/general';

const toDashboard = () => '/dashboard';

const toInternalError = (code?: string, message?: string) => {
  const params = new URLSearchParams();

  if (code) {
    params.set('code', code);
  }

  if (message) {
    params.set('message', message);
  }

  return `/500?${params}`;
};

const toLogin = () => '/';

const toReportedUser = (id: Uuid) => `/dashboard/reported-users/${id}`;

const toReportedUsers = () => '/dashboard/reported-users';

const toUploadedPhotos = () => '/dashboard/uploaded-photos';

const url = {
  toDashboard,
  toInternalError,
  toLogin,
  toReportedUser,
  toReportedUsers,
  toUploadedPhotos,
};

export default url;
