import React, { FC } from 'react';

import { BaseLayout } from 'layouts';
import { LogoDark } from 'components/icons';
import { Col, Container, Row } from 'components/ui';
import { BaseLayoutProps } from 'types/layouts';

import styles from './styles.module.css';

const PageLayout: FC<BaseLayoutProps> = ({ children, ...props }) => (
  <BaseLayout {...props}>
    <Container className={styles.container}>
      <Row className={styles.wrapper}>
        <Col className={styles.aside} md={12} xs={24}>
          <LogoDark className={styles.logo} />
        </Col>
        <Col className={styles.content} md={12} xs={24}>
          {children}
        </Col>
      </Row>
    </Container>
  </BaseLayout>
);

export default PageLayout;
