import React, { FC, useState } from 'react';
import { ArrowLeftOutlined, CloseOutlined, StopOutlined, WarningOutlined } from '@ant-design/icons';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { config } from 'data';
import { url } from 'helpers';
import { useMutation } from 'hooks';
import { complaintService } from 'services';
import { Button, Flex, Modal, Text } from 'components/ui';
import { Complaint, ComplaintStatus } from 'types/api';

enum EModal {
  ACCEPT = 'ACCEPT',
  REJECT = 'REJECT',
}

type ActionsProps = {
  complaint: Complaint;
};

const Actions: FC<ActionsProps> = ({ complaint }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [activeModal, setActiveModal] = useState<EModal>();

  const invalidateComplaintQueries = async () => {
    await queryClient.invalidateQueries({ queryKey: [config.COMPLAINTS_QUERY_KEY] });
    await queryClient.invalidateQueries({ queryKey: [config.COMPLAINT_QUERY_KEY, complaint.id] });
  };

  const acceptComplaintMutation = useMutation({
    mutationFn: complaintService.acceptComplaint,
    onSuccess: invalidateComplaintQueries,
  });

  const rejectComplaintMutation = useMutation({
    mutationFn: complaintService.rejectComplaint,
    onSuccess: invalidateComplaintQueries,
  });

  const showModal = (modal: EModal) => setActiveModal(modal);

  const hideModal = () => setActiveModal(undefined);

  return (
    <Flex
      gap="small"
      flex="100%"
      align="center"
      justify="space-between"
      wrap="wrap"
    >

      <Button
        type="default"
        icon={<ArrowLeftOutlined />}
        onClick={() => navigate(url.toReportedUsers())}
      >
        Back
      </Button>

      {complaint.status === ComplaintStatus.NEW && (
        <Flex gap="small" align="center" wrap="wrap">
          <Button
            type="default"
            icon={<CloseOutlined />}
            primary
            loading={rejectComplaintMutation.isPending}
            disabled={rejectComplaintMutation.isPending}
            onClick={() => showModal(EModal.REJECT)}
          >
            Reject
          </Button>
          <Modal
            title="Are you sure you want to reject the complaint?"
            open={activeModal === EModal.REJECT}
            onOk={() => {
              rejectComplaintMutation.mutate(complaint.id);

              hideModal();
            }}
            onCancel={() => hideModal()}
          />
          <Button
            icon={<StopOutlined />}
            loading={acceptComplaintMutation.isPending}
            disabled={acceptComplaintMutation.isPending}
            onClick={() => showModal(EModal.ACCEPT)}
          >
            Block user
          </Button>
          <Modal
            title="Are you sure you want to block the user?"
            open={activeModal === EModal.ACCEPT}
            onOk={() => {
              acceptComplaintMutation.mutate(complaint.id);

              hideModal();
            }}
            onCancel={() => hideModal()}
          >
            <Text type="warning">
              <WarningOutlined /> It'll prevent them from further using the application.
            </Text>
          </Modal>
        </Flex>
      )}

    </Flex>
  );
};

export default Actions;
