import React, { FC } from 'react';
import { Row as BaseRow, RowProps } from 'antd';

const Row: FC<RowProps> = ({ gutter = 48, ...props }) => (
  <BaseRow
    gutter={gutter}
    {...props}
  />
);

export default Row;
