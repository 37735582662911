import React, { FC } from 'react';
import { Button as BaseButton, ButtonProps as BaseButtonProps } from 'antd';
import cx from 'classnames';

import styles from './styles.module.css';

type ButtonProps = BaseButtonProps & {
  primary?: boolean;
};

const Button: FC<ButtonProps> = ({
  className,
  type = 'primary',
  size = 'large',
  shape = 'round',
  primary,
  ...props
}) => (
  <BaseButton
    className={cx(
      styles.root,
      primary && styles.root_primary,
      className,
    )}
    type={type}
    size={size}
    shape={shape}
    {...props}
  />
);

export default Button;
