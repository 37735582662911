import { axios } from 'data';
import { Uuid } from 'types/general';

const blockUser = async (id: Uuid) => {
  const response = await axios.put<never>(`/admin/user/${id}/block`);

  return response.status === 200;
};

const photoService = {
  blockUser,
};

export default photoService;
