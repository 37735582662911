import React, { FC, ReactNode } from 'react';
import { Button, ButtonProps, Flex, Tooltip } from 'antd';

import styles from './styles.module.css';

type ActionsButton = ButtonProps & {
  title: string;
  icon: ReactNode;
};

type ActionsProps = {
  buttons: ActionsButton[];
};

const Actions: FC<ActionsProps> = ({ buttons }) => (
  <Flex gap="small" align="center">
    {buttons.map(({ title, ...props }, index) => (
      <Tooltip key={index} title={title}>
        <Button className={styles.button} shape="circle" {...props} />
      </Tooltip>
    ))}
  </Flex>
);

export default Actions;
