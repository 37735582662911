import React from 'react';

import { useAuth } from 'hooks';
import { PageLayout } from 'layouts';
import { Button, Paragraph, Title } from 'components/ui';

import styles from './styles.module.css';

const LoginPage = () => {
  const auth = useAuth();

  return (
    <PageLayout title="Login">
      <div className={styles.root}>
        <Title>Welcome!</Title>
        <Paragraph>Login to be able to use the admin panel.</Paragraph>
        <Button block onClick={auth.login}>Login</Button>
      </div>
    </PageLayout>
  );
};

export default LoginPage;
