import { FC } from 'react';

import { ComplaintType as EComplaintType } from 'types/api';

type ComplaintTypeProps = {
  type: EComplaintType;
};

const typeMap: Record<EComplaintType, string> = {
  [EComplaintType.SPAM]: 'Spam',
  [EComplaintType.FAKE_ACCOUNT]: 'Fake account',
  [EComplaintType.VIOLENCE]: 'Violence',
  [EComplaintType.PORNOGRAPHY]: 'Pornography',
  [EComplaintType.CHILD_ABUSE]: 'Child abuse',
  [EComplaintType.OTHER]: 'Other',
};

const ComplaintType: FC<ComplaintTypeProps> = ({ type }) => typeMap[type] ?? type;

export default ComplaintType;
