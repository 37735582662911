import React, { FC, ReactNode } from 'react';
import { RotateLeftOutlined, RotateRightOutlined, ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons';
import { Flex, Image as BaseImage, ImageProps as BaseImageProps } from 'antd';
import { ImagePreviewType } from 'rc-image';

import { Override } from 'types/general';

import styles from './styles.module.css';

type ImageProps = Override<BaseImageProps, {
  preview?: boolean | ImagePreviewType & {
    extraToolbar?: ReactNode;
  };
}>;

const Image: FC<ImageProps> = ({ loading = 'lazy', preview = true, ...props }) => (
  <BaseImage
    loading={loading}
    preview={preview && {
      toolbarRender: (_, info) => (
        <Flex
          className={styles.toolbar}
          align="center"
          justify="center"
          wrap="wrap"
        >
          <RotateLeftOutlined onClick={info.actions.onRotateLeft} />
          <RotateRightOutlined onClick={info.actions.onRotateRight} />
          <ZoomOutOutlined disabled={info.transform.scale === 1} onClick={info.actions.onZoomOut} />
          <ZoomInOutlined disabled={info.transform.scale === 50} onClick={info.actions.onZoomIn} />
          {typeof preview === 'object' && preview.extraToolbar && (
            <>
              <span className={styles.toolbar__separator}>|</span>
              {preview.extraToolbar}
            </>
          )}
        </Flex>
      ),
      ...(typeof preview === 'object' ? preview : {}),
    }}
    {...props}
  />
);

export default Image;
